#pagelogo {
   padding: 1rem;
}

#centered {
    text-align: center;
    margin: auto;
    box-sizing: border-box;
    display: block;
    padding: 1%;
    width: 80%;
 }

 .yt {
   position: relative;
   display: block;
   width: 90%; /* width of iframe wrapper */
   height: 0;
   margin: auto;
   padding: 0% 0% 56.25%; /* 16:9 ratio */
   overflow: hidden;
 }
 .yt iframe {
   position: absolute;
   top: 0; bottom: 0; left: 0;
   width: 100%;
   height: 100%;
   border: 0;
 }

div {
    font-size: 20px;
    color: #0a0f0f;
}



