* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

a:link {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.PrivateRadioButtonIcon-root-28,
.PrivateRadioButtonIcon-root-19 {
  color: #8f0af7 !important;
}
.PrivateRadioButtonIcon-checked-30,
.PrivateRadioButtonIcon-checked-21 {
  color: #fff !important;
}
.makeStyles-textCenter-10 {
  color: #8f0af7 !important;
}
.makeStyles-faq-32 {
  color: #8f0af7 !important;
}
.MuiInput-underline:before {
  border-color: #8f0af7 !important;
}
.MuiInputBase-root {
  /* color: black!important; */
  color: #8f0af7 !important;
}
.makeStyles-modalBody-3 h3,
h2,
h4 {
  color: #8f0af7 !important;
}
h3 {
  /* color: #8f0af7!important; */
  color: #8f0af7 !important;
}

.MuiSvgIcon-root {
  color: #8f0af7;
  /* color: green; */
}

input[type="date"] {
  font: inherit;
  color: currentColor;
  padding: 6px 5px 7px;

  border: none;
  border-bottom: 1px solid #8f0af7;
  outline: none;
}

input[placeholder="mm/dd/yyyy"] {
  color: white !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);

  /* background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat; */
}

.MuiFormControl-root {
  width: 100%;
}

.vertical {
  width: 25px;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 4px;
  transform: rotate(90deg);
  display: inline-block;
}
.bar-inner {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 4px;
}
.bar-inner-active {
  background-color: #27ae60 !important;
}
.bar-inner-active-orange {
  background-color: #f2994a !important;
}
.bar-inner-active-red {
  background-color: #eb5656 !important;
}
.horizontal {
  width: 80px;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 4px;
  transform: rotate(-360deg);
  display: inline-block;
}
.bar-text {
  width: 25px;
  height: 19px;
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #e0e0e0;
  margin-left: 80px;
  display: inline-block;
}
.bar-text-active {
  color: #219653 !important;
}

.bar-text-active-orange {
  color: #f2994a !important;
}

.bar-text-active-red {
  color: #eb5656 !important;
}

.active-bg {
  background-color: #e3f4ea;
}
.active-bg-orange {
  background-color: #fdefe2;
}
.active-bg-red {
  background-color: #fce7e7;
}
